import {AppState} from '../store';
import {getReportingStoreLive} from '../api/fetchers/getReportingStoreLive';
import {makeStyles} from '@material-ui/core/styles';
import {QueryKeys} from '../api/queryKeys';
import {useHandledQuery} from '../shared/useHandledQuery/useHandledQuery';
import {useSelector} from 'react-redux';
import {useTheme} from '@material-ui/core';
import AlarmsHeader from './layout/alarmsHeader';
import Box from '@material-ui/core/Box';
import CanvasHeader from './layout/canvasHeader';
import Content from 'components/content/content';
import Grid from '@material-ui/core/Grid';
import Header from 'components/header/header';
import LayoutCanvas from './layout/canvas';
import Main from 'components/main/main';
import Navigation from 'components/navigation/navigation';
import React from 'react';
import Konva from 'konva';
import AlarmsBody from './layout/alarmsBody';
import {fetchStores} from "../api/fetchers/stores";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            '& #header:fullscreen, & #navigation:fullscreen': {
                display: 'none',
            },
        },
    };
});

const StaticLayoutScreen: React.FC = () => {
    const theme = useTheme();
    const classes = useStyles();

    const {storeId} = useSelector((state: AppState) => state.store);
    const {data: storesDataFilteredByStoreId} = useHandledQuery(
        [QueryKeys.store(storeId || 0), storeId, 'navigation'],
        () => fetchStores(undefined, storeId ? [{column: 'id', value: storeId.toString(), operator: 'eq'}] : undefined),
        {
            staleTime: Infinity,
            enabled: Boolean(storeId),
        },
    );
    const url = storesDataFilteredByStoreId
        && storesDataFilteredByStoreId.length > 0
        && storesDataFilteredByStoreId[0].staticLayout !== null
            ? '/assets/layouts/' + storesDataFilteredByStoreId[0].staticLayout
            : '/assets/layouts/default.png';

    return (
        <div className={classes.root}>
            <>
                <Header/>
                <Navigation/>
            </>
            <Main>
                <Content>
                    <img src={url} width="100%" />
                </Content>
            </Main>
        </div>
    );
};

export default StaticLayoutScreen;
