import { AppState } from '../../store';
import { Capabilities } from 'shared/useCapabilities/capabilities';
import { fetchStores } from '../../api/fetchers/stores';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { QueryKeys } from '../../api/queryKeys';
import { routes } from 'routes/routes';
import { useHandledQuery } from '../../shared/useHandledQuery/useHandledQuery';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useCapabilities from 'shared/useCapabilities/useCapabilities';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { INavigationItems } from 'components/navigation/types';
import { getAlarmReportActiveCount } from 'api/fetchers/alarmReportActiveCount';
import { formatDateForAPI } from 'shared/network/helpers';
import { DateTimeWithTimezone } from 'shared/utils/dateWithTimezone';

const useStyles = makeStyles((theme) => {
    const TAB_HEIGHT = {
        small: theme.spacing(4),
        medium: theme.spacing(6),
        large: theme.spacing(7),
    };
    return {
        root: {
            position: 'relative',
            zIndex: 1,
            background: theme.customPalette.background.light,
            boxShadow: theme.customElevation.default,
        },
        indicator: {
            background: theme.customPalette.colors.brand.light,
        },
        tabs: {
            minHeight: TAB_HEIGHT.small,
            [theme.breakpoints.up('sm')]: {
                minHeight: TAB_HEIGHT.medium,
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: TAB_HEIGHT.large,
            },
        },
        tab: {
            minHeight: TAB_HEIGHT.small,
            [theme.breakpoints.up('sm')]: {
                minHeight: TAB_HEIGHT.medium,
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: TAB_HEIGHT.large,
            },
            height: '100%',
            minWidth: 'auto',
            textTransform: 'none',
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 500,
            color: theme.customPalette.colors.brand.light,
            '&$selected': {
                color: theme.customPalette.text.primary,
            },
        },
        selected: {},
        tabWrapper: {
            flexDirection: 'row',
            '& svg': {
                marginTop: theme.typography.pxToRem(6),
                marginRight: theme.spacing(1),
            },
        },
    };
});

const Navigation: React.FC = () => {
    const { isAuthorized } = useCapabilities();
    const classes = useStyles();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const items: INavigationItems[] = [];

    const { storeId } = useSelector((state: AppState) => state.store);
    const { data: storesDataFilteredByStoreId } = useHandledQuery(
        [QueryKeys.store(storeId), storeId, 'navigation'],
        () => fetchStores(undefined, storeId ? [{ column: 'id', value: storeId.toString(), operator: 'eq' }] : undefined),
        {
            staleTime: Infinity,
            enabled: Boolean(storeId),
        },
    );
    const { data: alarmReportData } = useHandledQuery(
        [QueryKeys.getAlarmReportCount()],
        () =>
            storeId
                ? getAlarmReportActiveCount({
                      storeId,
                      dateFrom: formatDateForAPI(DateTimeWithTimezone),
                      dateTo: formatDateForAPI(DateTimeWithTimezone),
                  })
                : undefined,
        { enabled: Boolean(storeId) },
    );

    items.push({
        id: 'foodSafety',
        path: routes.foodSafetyReporting.path(),
        pattern: routes.foodSafety.pattern,
        text: t('navigation.foodSafety'),
    });

    if (isAuthorized(Capabilities.ENERGY_METRIC_LIST_ALL)) {
        items.push({
            id: 'energy',
            path: routes.energyConsumption.path(),
            pattern: routes.energy.pattern,
            text: t('navigation.energy'),
        });
    }

    items.push({
        id: 'setup',
        path: routes.setupEmailReportConfig.path(),
        pattern: routes.setup.pattern,
        text: t('navigation.setup'),
    });

    if (isAuthorized(Capabilities.LIST_USERS)) {
        items.push({
            id: 'users',
            path: routes.users.path(),
            pattern: routes.users.pattern,
            text: t('navigation.users'),
        });
    }

    if (alarmReportData?.data.alarms.length) {
        items.push({
            id: 'alarm',
            path: routes.alarmListView.path(),
            pattern: routes.alarm.pattern,
            text: t('navigation.alarm'),
        });
    }

    if (storesDataFilteredByStoreId && (storesDataFilteredByStoreId[0].liveData)) {
        items.push({
            id: 'layout',
            path: routes.layout.path(),
            pattern: routes.layout.pattern,
            text: t('navigation.layout'),
        });
    } else if (storesDataFilteredByStoreId && storesDataFilteredByStoreId[0].staticLayout !== null) {
        items.push({
            id: 'staticLayout',
            path: routes.staticLayout.path(),
            pattern: routes.staticLayout.pattern,
            text: t('navigation.layout'),
        });
    }
    if (storesDataFilteredByStoreId && storesDataFilteredByStoreId[0].hasReports) {
        items.push({
            id: 'reports',
            path: routes.reports.path(),
            pattern: routes.reports.pattern,
            text: t('navigation.reports'),
        });
    }

    const activeItem = items.find((item) => {
        return !!matchPath(pathname, { path: item.pattern, strict: false });
    });

    if (!activeItem) {
        return null;
    }
    return (
        <div className={classes.root} id="navigation">
            <Tabs
                centered
                classes={{
                    root: classes.tabs,
                    indicator: classes.indicator,
                }}
                indicatorColor="primary"
                textColor="primary"
                value={activeItem && activeItem.id}
                variant={isSmUp ? 'standard' : 'fullWidth'}
            >
                {items.map((item) => (
                    <Tab
                        key={item.id}
                        classes={{
                            wrapper: classes.tabWrapper,
                            selected: classes.selected,
                        }}
                        className={classes.tab}
                        component={Link}
                        id={`test-navigation-${item.id}`}
                        label={item.text}
                        to={item.path}
                        value={item.id}
                    />
                ))}
            </Tabs>
        </div>
    );
};

export default Navigation;
